/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Card = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    h3 {
        font-size: 32px;
        font-weight: 600;
        line-height: 1.15;
    @media screen and (max-width: 700px) {
        font-size: 22px;
    }
    }
    h3,
    p {
        text-align: left;
    }
    .icw svg {
        width: 60px;
        height: auto;
    }
    .icw svg * {
        transition: 0.5 ease;
    }
    transition: 0.5s ease;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 3px 5px 17px 5px rgba(0, 0, 0, 0.12);
    &:hover {
        .icw svg * {
            fill: white !important;
        }
        background-color: #03a9f4;
        h3,
        p {
            color: white;
        }
        box-shadow: 3px 5px 38px 5px rgba(0, 0, 0, 0.4);
        svg.ic {
          color: white;
        }
        a {
          color: white;
        }
    }
    a {
      color: '#1a1a1a';
    }
`;

const Wrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  flex-direction: column;
`;

const IconStyle = css`
  vertical-align: -15%;
  padding-left: 4px;
  color:  #03a9f4;
`;

const CardActionLink = styled.p`
  margin-top: auto;
`;

export const IconRight = () => (<ArrowForwardIcon className="ic" css={IconStyle} />);

const FeatureCard = ({
  title, desc, icon, link, to, lg,
}) => (
  <Grid item xs={12} sm={lg ? 12 : 6} md={6} lg={lg ? 6 : 4}>
    <Card>
      <Wrapper>
        <h3>{title}</h3>
        <IconWrapper className="icw">{icon}</IconWrapper>
      </Wrapper>
      <p>{desc}</p>
      {link
      && (
      <CardActionLink className="fc-action">
        <Link to={to}>
          {link}
          {' '}
          <ArrowForwardIcon className="ic" css={IconStyle} />
        </Link>
      </CardActionLink>
      )}
    </Card>

  </Grid>
);

export default FeatureCard;

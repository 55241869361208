/* eslint-disable react/prop-types */
import { css } from '@emotion/react';
import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import FeatureCard from './featureCard';

const spaced = css`
    margin-bottom: 80px;
`;

const FeatureCardsSection = ({
  title, subtitle, cards, lg, id,
}) => (
  <Container id={id}>
    <Box mt={12} mb={12}>
      <h2>
        {title}
      </h2>
      <h5 css={spaced}>{subtitle}</h5>
      <Grid container spacing={lg ? 4 : 4}>
        {cards.map((card) => (
          <FeatureCard
            lg={lg}
            key={card.to || card.title}
            title={card.title}
            desc={card.desc}
            icon={card.icon}
            link={card.link}
            to={card.to}
          />
        ))}
      </Grid>
    </Box>
  </Container>
);

export default FeatureCardsSection;
